import { AddressSearch } from '@application/components';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Tooltip, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SiteLookupContext from '../context/SiteLookupContext';
import SiteLookupMapContext from '../context/SiteLookupMapContext';
import RadiusFilter from '../filters/RadiusFilter';
import DownloadReportMenu from './DownloadReportMenu';
import DownloadButtonTooltip from './DownloadButtonTooltip';

const SearchBar = () => {
  const { downloadReport, filters, updateFilterAddress, updateFilterRadius, remainingDownloads } = useContext(SiteLookupContext);
  const { t } = useTranslation();

  const ref = useRef<HTMLElement>(null);

  const { palette, zIndex } = useTheme();

  const { hasSitesIncludedInReport, searchLimit } = useContext(SiteLookupMapContext);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const isDownloadReportDisabled = useMemo(
    () => remainingDownloads <= 0 || !filters.address || !!searchLimit?.exceeded || !hasSitesIncludedInReport,
    [filters.address, hasSitesIncludedInReport, remainingDownloads, searchLimit],
  );

  return (
    <Box
      position="sticky"
      display="flex"
      gap={3}
      paddingX={3}
      paddingY={2}
      sx={{
        top: 'calc(var(--top-bar-height) + 1px)',
        zIndex: zIndex.fab,
        minHeight: 'var(--search-bar-height)',
        backgroundColor: palette.common.white,
        boxShadow: '0 0 2px rgb(0 0 0 / 20%)',
      }}
    >
      <Box minWidth={{ xs: 300, lg: 400, xl: 460 }}>
        <AddressSearch onSelect={updateFilterAddress} ref={ref} value={filters.address?.fullAddress || ''} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <RadiusFilter onChange={updateFilterRadius} value={filters.radius} />
      </Box>

      {searchLimit?.exceeded && (
        <Tooltip
          arrow
          placement="top"
          sx={{ width: 100 }}
          title={<Trans i18nKey={'siteLookup.searchLimitExceeded'} t={t} values={{ limit: searchLimit.count }} />}
        >
          <WarningAmberOutlined sx={{ alignSelf: 'center', color: 'warning.dark' }} />
        </Tooltip>
      )}

      <DownloadButtonTooltip>
        <Box sx={{ alignSelf: 'center', height: 40, minWidth: 'fit-content' }}>
          <DownloadReportMenu onDownloadReport={downloadReport} isDownloadReportDisabled={isDownloadReportDisabled} />
        </Box>
      </DownloadButtonTooltip>
    </Box>
  );
};

export default SearchBar;

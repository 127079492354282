import { UserContext } from '@application/contexts';
import { ReportAggregation, UserRole } from '@domain/graphql.types';
import { LockOutlined } from '@mui/icons-material';
import { Divider, Link, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LinkComponent from '../link-component/LinkComponent';
import { Routes } from '@application/routes';

type Props = {
  anchorEl: HTMLElement | null;
  onAggregationSelected: (aggregation: ReportAggregation) => () => void;
  onClose: () => void;
  open: boolean;
};

const ReportAggregationMenu = ({ anchorEl, onAggregationSelected, onClose, open }: Props) => {
  const { t } = useTranslation();

  const { hasSupportForAggregation, role, tenant } = useContext(UserContext);

  const isWeeklyDisabled = !hasSupportForAggregation(ReportAggregation.Weekly);
  const isDailyDisabled = !hasSupportForAggregation(ReportAggregation.Daily);

  const showLinkToSubscription = role === UserRole.Owner;

  return (
    <Menu anchorEl={anchorEl} elevation={2} open={open} onClose={onClose} slotProps={{ paper: { sx: { maxWidth: 340 } } }}>
      <MenuItem
        sx={{
          cursor: 'unset',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          whiteSpace: 'unset',
        }}
      >
        <ListItemText>
          <Trans
            i18nKey={'aggregationMenu.subscriptionInfo'}
            t={t}
            values={{ count: tenant?.remainingDownloads }}
            components={{
              Upgrade: showLinkToSubscription ? (
                <Link color="inherit" component={LinkComponent} href={Routes.TENANT_SUBSCRIPTION} sx={{ fontWeight: 'bold' }}>
                  {t('siteLookup.upgrade')}
                </Link>
              ) : (
                <></>
              ),
            }}
          />
        </ListItemText>
      </MenuItem>

      <Divider component="li" />

      <Tooltip disableInteractive={!isDailyDisabled} title={isDailyDisabled ? t('aggregationMenu.aggregationLevelNotSupported') : null} arrow placement="left">
        <span>
          <MenuItem
            disabled={isDailyDisabled}
            onClick={onAggregationSelected(ReportAggregation.Daily)}
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
          >
            <ListItemText>{t('aggregationMenu.dailyAggregation')}</ListItemText>

            {isDailyDisabled && <LockOutlined fontSize="small" sx={{ marginLeft: 'auto' }} />}
          </MenuItem>
        </span>
      </Tooltip>

      <Tooltip
        disableInteractive={!isWeeklyDisabled}
        title={isWeeklyDisabled ? t('aggregationMenu.aggregationLevelNotSupported') : null}
        arrow
        placement="left"
      >
        <li>
          <MenuItem
            disabled={isWeeklyDisabled}
            onClick={onAggregationSelected(ReportAggregation.Weekly)}
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
          >
            <ListItemText>{t('aggregationMenu.weeklyAggregation')}</ListItemText>

            {isWeeklyDisabled && <LockOutlined fontSize="small" sx={{ marginLeft: 'auto' }} />}
          </MenuItem>
        </li>
      </Tooltip>
    </Menu>
  );
};

export default ReportAggregationMenu;

import React, { useContext, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { LinkComponent } from '@application/components';
import { Routes } from '@application/routes';
import SiteLookupContext from '../context/SiteLookupContext';
import { PlatformPricingTier, UserRole } from '@domain/graphql.types';
import { UserContext } from '@application/contexts';
import SiteLookupMapContext from '../context/SiteLookupMapContext';

type Props = {
  children: React.ReactElement;
};

const DownloadButtonTooltip = ({ children }: Props) => {
  const { t } = useTranslation();

  const { hasSitesIncludedInReport } = useContext(SiteLookupMapContext);
  const { remainingDownloads } = useContext(SiteLookupContext);
  const { role, tenant } = useContext(UserContext);

  const showLinkToSubscription = role === UserRole.Owner && tenant?.platformPricingTier === PlatformPricingTier.Free;

  const userHasNoDownloads = useMemo(() => remainingDownloads <= 0, [remainingDownloads]);

  const title = useMemo(() => {
    if (!hasSitesIncludedInReport) {
      return t('siteLookup.downloadReportNoSitesIncluded');
    }

    if (userHasNoDownloads) {
      return (
        <Trans
          i18nKey={'tooltips.notEnoughDownloads'}
          t={t}
          components={{
            LearnMore: showLinkToSubscription ? (
              <Link color="inherit" component={LinkComponent} href={Routes.TENANT_SUBSCRIPTION}>
                {t('siteLookup.learnMore')}
              </Link>
            ) : (
              <></>
            ),
          }}
        />
      );
    }

    return null;
  }, [userHasNoDownloads, hasSitesIncludedInReport, showLinkToSubscription, t]);

  return (
    <Tooltip disableInteractive={!userHasNoDownloads && hasSitesIncludedInReport} placement="top" title={title}>
      {children}
    </Tooltip>
  );
};

export default DownloadButtonTooltip;

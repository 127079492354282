import { PlatformPricingTier } from '@domain/graphql.types';

export const paidPlatformPricingTiers = [
  PlatformPricingTier.First,
  PlatformPricingTier.Second,
  PlatformPricingTier.Third,
  PlatformPricingTier.Fourth,
  PlatformPricingTier.Fifth,
  PlatformPricingTier.Sixth,
];

import { UserContext } from '@application/contexts';
import { UserContextProps } from '@application/contexts/UserContext';
import { ReportAggregation, Tenant, TenantPermissions, User, UserRole, UserStatus } from '@domain/graphql.types';
import useMeQuery from '@domain/users/useMeQuery';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

type UserProviderProps = PropsWithChildren<unknown>;

const UserProvider = ({ children }: UserProviderProps) => {
  const [initializationCompleted, setInitializationCompleted] = useState(false);
  const [hasFinishedOnboarding, setFinishedOnboarding] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [role, setRole] = useState<UserRole | undefined>();
  const [tenant, setTenant] = useState<Tenant | undefined>();
  const [tenantPermissions, setTenantPermissions] = useState<TenantPermissions | undefined>();
  const [isInvitedUser, setIsInvitedUser] = useState(false);
  const [version, setVersion] = useState<string | null>(null);

  const { data, fetching, error, reexecuteQuery } = useMeQuery({ pause: true });
  const initializeUser = useCallback(() => {
    reexecuteQuery();
  }, [reexecuteQuery]);

  useEffect(() => {
    if (!data?.me?.user) {
      return;
    }

    setInitializationCompleted(!!data?.me?.user);
    setUser({ ...data.me.user });
    setRole(data.me.user.role || undefined);
    setFinishedOnboarding(data.me.user.hasCompletedOnboarding);
    setIsInvitedUser(data.me.user.status === UserStatus.Invited);
    setTenant(data.me.user.defaultTenant || undefined);
    setTenantPermissions(data.me.user.defaultTenant?.permissions || undefined);
    setVersion(data.me.user.version);
  }, [data]);

  const hasSupportForAggregation = useCallback(
    (aggregation: ReportAggregation) => {
      if (!tenantPermissions?.aggregationLevels.length) {
        return false;
      }

      return tenantPermissions.aggregationLevels.includes(aggregation);
    },
    [tenantPermissions],
  );

  const value = useMemo(
    (): UserContextProps => ({
      error,
      initializationCompleted,
      initializeUser,
      isEmailVerified: !!user?.isEmailVerified,
      isInvitedUser,
      isLoading: fetching,
      hasFinishedOnboarding,
      hasSupportForAggregation,
      refreshUser: reexecuteQuery,
      user,
      role,
      tenant,
      tenantPermissions,
      version,
    }),
    [
      error,
      initializationCompleted,
      isInvitedUser,
      hasFinishedOnboarding,
      hasSupportForAggregation,
      initializeUser,
      role,
      user,
      fetching,
      reexecuteQuery,
      tenant,
      tenantPermissions,
      version,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;

import { UserContext } from '@application/contexts';
import { useStepper } from '@application/hooks';
import { extractErrorCodes } from '@application/utils/urql-utils';
import { dispatchLogout } from '@domain/authentication';
import { useCompleteOnboarding } from '@domain/onboarding';
import { useSendOtpVerification } from '@domain/otp';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ONBOARDING_SCHEMA, OnboardingSchemaFormFields } from './OnboardingForm.schema';

const useOnboardingForm = () => {
  const [isVerifying, setIsVerifying] = useState(false);

  const { refreshUser, isInvitedUser, user } = useContext(UserContext);

  const { activeStep, handleBack: handleBackStepper, handleNext } = useStepper({ initialStep: 1 });

  const methods = useForm<OnboardingSchemaFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(ONBOARDING_SCHEMA),
  });

  const { clearErrors, getFieldState, trigger, watch, setError, setValue } = methods;

  const phone = watch('phone');

  const { completeOnboarding, fetching: isSubmitting } = useCompleteOnboarding();
  const { sendOtpVerification, fetching: isSendingVerification, error: sendVerificationError, data: sendVerificationData } = useSendOtpVerification();

  useEffect(() => {
    const errors = extractErrorCodes(sendVerificationError);

    if (!errors.length) {
      return;
    }

    if (errors.some(({ status }) => status === 409)) {
      setError('phone', {
        message: 'validations.phone.alreadyInUse',
        type: 'onChange',
      });
    } else {
      setError('phone', {
        message: 'validations.phone.unknown',
        type: 'onChange',
      });
    }
  }, [sendVerificationError, setError]);

  useEffect(() => {
    if (sendVerificationData?.sendOtpVerification?.ok) {
      setIsVerifying(true);
    }
  }, [sendVerificationData]);

  useEffect(() => {
    if (!user?.defaultTenant) {
      return;
    }

    setValue('tenantName', user.defaultTenant.displayName || '');
    setValue('address', user.defaultTenant.address);
  }, [setValue, user?.defaultTenant]);

  const finalizeOnboarding = useCallback(
    async (fields: OnboardingSchemaFormFields): Promise<void> => {
      const { data } = await completeOnboarding({
        input: fields,
      });

      if (data?.completeOnboarding?.user) {
        handleNext();

        setTimeout(() => refreshUser(), 3000);
      }
    },
    [completeOnboarding, handleNext, refreshUser],
  );

  const handleContinue = useCallback(
    (keys: (keyof OnboardingSchemaFormFields)[]) => async () => {
      await trigger(keys);

      const invalid = keys.map((key) => getFieldState(key)).some(({ invalid }) => invalid);

      if (!invalid) {
        handleNext();
      }
    },
    [getFieldState, handleNext, trigger],
  );

  const handleBack = useCallback(() => {
    activeStep === 1 ? dispatchLogout() : handleBackStepper();
  }, [activeStep, handleBackStepper]);

  const sendVerificationCode = useCallback(async () => {
    clearErrors('phone');

    const valid = await trigger('phone');

    if (!valid) {
      return;
    }

    sendOtpVerification({ input: { to: phone } });
  }, [clearErrors, phone, trigger, sendOtpVerification]);

  const changePhone = useCallback(() => {
    setValue('phone', '', { shouldValidate: false });
    setIsVerifying(false);
  }, [setValue]);

  const Provider = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => <FormProvider {...methods}>{children}</FormProvider>,
    [methods],
  );

  return {
    actions: {
      changePhone,
      sendVerificationCode,
    },
    form: {
      handleSubmit: methods.handleSubmit(finalizeOnboarding),
      Provider,
    },
    state: {
      isSendingVerification,
      isSubmitting,
      isVerifying,
      isInvitedUser,
    },
    stepper: {
      activeStep,
      handleBack,
      handleContinue,
    },
  };
};

export default useOnboardingForm;

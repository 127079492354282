import FileSparkIcon from '@application/assets/FileSparkIcon';
import { ReportAggregationMenu } from '@application/components';
import { ReportAggregation } from '@domain/graphql.types';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled: boolean;
  onGenerateReport: (aggregation: ReportAggregation) => void;
  generatingReport: boolean;
};

const GenerateReportMenu = ({ disabled, onGenerateReport, generatingReport }: Props) => {
  const [generateReportMenuAnchor, setGenerateReportMenuAnchor] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleGenerateReportMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setGenerateReportMenuAnchor(event.currentTarget);
  }, []);

  const handleGenerateReportMenuClose = useCallback(() => {
    setGenerateReportMenuAnchor(null);
  }, []);

  const handleGenerateAndClose = useCallback(
    (aggregation: ReportAggregation) => () => {
      onGenerateReport(aggregation);
      handleGenerateReportMenuClose();
    },
    [onGenerateReport, handleGenerateReportMenuClose],
  );

  return (
    <Box>
      <LoadingButton
        disabled={disabled}
        endIcon={<KeyboardArrowDownOutlined />}
        startIcon={<FileSparkIcon />}
        variant="contained"
        onClick={handleGenerateReportMenuClick}
        loading={generatingReport}
      >
        {t('bulkSearch.generateReport')}
      </LoadingButton>

      <ReportAggregationMenu
        anchorEl={generateReportMenuAnchor}
        onAggregationSelected={handleGenerateAndClose}
        onClose={handleGenerateReportMenuClose}
        open={!!generateReportMenuAnchor}
      />
    </Box>
  );
};

export default GenerateReportMenu;

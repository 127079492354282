export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** File upload scalar type */
  Upload: { input: any; output: any };
};

export enum AddressErrors {
  /** When two or more records in the CSV file have duplicate IDs. */
  DuplicateId = 'DUPLICATE_ID',
  /** When the bulk file has invalid headers. */
  InvalidHeaders = 'INVALID_HEADERS',
  /** When the bulk file has an invalid record. */
  InvalidRecord = 'INVALID_RECORD',
}

/**
 * An address from a bulk search request file.
 *     Contains user defined information (id, name and full_address) as-well as system defined information (coordinates).
 *     This address will be used with other to generate a bulk report.
 *
 */
export type BulkSearchAddressItem = {
  readonly __typename?: 'BulkSearchAddressItem';
  /** coordinates of the address when it is validated. If the address is not valid, those coordinates could be incorrect. */
  readonly coordinates: GeoCoordinates;
  /** full address used for validation */
  readonly fullAddress: Scalars['String']['output'];
  /** name given by the user */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** state code returned by validation. If the address is not valid, this will be null. */
  readonly stateCode?: Maybe<Scalars['String']['output']>;
  /** id given by the user */
  readonly userDefinedId: Scalars['String']['output'];
  /** whether the full address is a valid address or not */
  readonly valid: Scalars['Boolean']['output'];
};

/** Same thing as the BulkSearchAddressItem model, but as input */
export type BulkSearchAddressItemInput = {
  /** coordinates of the address when it is validated. If the address is not valid, those coordinates could be incorrect. */
  readonly coordinates: GeoCoordinatesInput;
  /** full address used for validation */
  readonly fullAddress: Scalars['String']['input'];
  /** name given by the user */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** state code returned by validation. If the address is not valid, this will be null. */
  readonly stateCode?: InputMaybe<Scalars['String']['input']>;
  /** id given by the user */
  readonly userDefinedId: Scalars['String']['input'];
  /** whether the full address is a valid address or not */
  readonly valid: Scalars['Boolean']['input'];
};

/** Request for checking if a given slug is available */
export type CheckTenantAvailabilityRequest = {
  /** the slug to check for availability */
  readonly slug: Scalars['String']['input'];
};

/** Response to the query for fetching an available tenant slug */
export type CheckTenantAvailabilityResponse = {
  readonly __typename?: 'CheckTenantAvailabilityResponse';
  /** whether the given tenant slug is available */
  readonly isAvailable: Scalars['Boolean']['output'];
};

/** Request for completing the onboarding of a user */
export type CompleteOnboardingRequest = {
  /** the address of the tenant associated to this user */
  readonly address: Scalars['String']['input'];
  /** whether the user has accepted the terms and conditions */
  readonly areTermsAndConditionsAccepted: Scalars['Boolean']['input'];
  /** the first name of the user */
  readonly firstName: Scalars['String']['input'];
  /** the last name of the user */
  readonly lastName: Scalars['String']['input'];
  /** otp code */
  readonly otpCode: Scalars['String']['input'];
  /** the tenant name this user is creating an account for */
  readonly phone: Scalars['String']['input'];
  /** the position of this user */
  readonly position: Scalars['String']['input'];
  /** the tenant name this user is creating an account for */
  readonly tenantName: Scalars['String']['input'];
};

/** Response of the onboarding completion */
export type CompleteOnboardingResponse = {
  readonly __typename?: 'CompleteOnboardingResponse';
  /** the (current) user that has just been updated */
  readonly user: User;
};

export enum DataSource {
  AdditionalSixMonthsHistory = 'ADDITIONAL_SIX_MONTHS_HISTORY',
  Tesla = 'TESLA',
}

/** Request for downloading a report */
export type DownloadReportRequest = {
  /** unique id of the report */
  readonly reportId: Scalars['String']['input'];
};

/** Request for generating the single address search report */
export type GenerateSingleAddressSearchReportRequest = {
  /** data aggregation for this report */
  readonly aggregation: ReportAggregation;
  /** coordinates to look for */
  readonly coordinates: GeoCoordinatesInput;
  /** the full address related to the coordinates */
  readonly fullAddress: Scalars['String']['input'];
  /** the range to search for */
  readonly range: Scalars['Int']['input'];
  /** the state code for the coordinates we are searching for */
  readonly stateCode: Scalars['String']['input'];
};

/** Representation of a coordinate with latitude and longitude */
export type GeoCoordinates = {
  readonly __typename?: 'GeoCoordinates';
  /** latitude */
  readonly latitude: Scalars['Float']['output'];
  /** longitude */
  readonly longitude: Scalars['Float']['output'];
};

/** For inputting geo coordinates */
export type GeoCoordinatesInput = {
  /** latitude */
  readonly latitude: Scalars['Float']['input'];
  /** longitude */
  readonly longitude: Scalars['Float']['input'];
};

/** Response to the query for fetching an available tenant slug */
export type GetAvailableTenantSlugResponse = {
  readonly __typename?: 'GetAvailableTenantSlugResponse';
  /** an available tenant slug */
  readonly slug: Scalars['String']['output'];
};

/** Request for searching sites */
export type GetSitesRequest = {
  /** coordinates to look for */
  readonly coordinates: GeoCoordinatesInput;
  /** the range to search for */
  readonly range: Scalars['Int']['input'];
  /** the state code for the coordinates we are searching for */
  readonly stateCode: Scalars['String']['input'];
};

/** Response of the sites search */
export type GetSitesResponse = {
  readonly __typename?: 'GetSitesResponse';
  /** the number of stations that will be included in a report due to permissions returned by the search */
  readonly includedInReportCount: Scalars['Int']['output'];
  /** the maximum number of ports tracked by Paren that the search can return */
  readonly limit: Scalars['Int']['output'];
  /** whether the maximum number of ports tracked by Paren that the search can return was exceeded or not */
  readonly limitExceeded: Scalars['Boolean']['output'];
  /** the sites return by the search */
  readonly results: ReadonlyArray<Site>;
  /** the number of stations returned by the search */
  readonly stationsCount: Scalars['Int']['output'];
  /** the number of ports tracked by Paren returned by the search */
  readonly trackedPortsCount: Scalars['Int']['output'];
};

/** Request for fetching the tenant billing customer secret */
export type GetTenantBillingCustomerSecretRequest = {
  /** the id of the tenant to get the billing customer secret from */
  readonly tenantId: Scalars['String']['input'];
};

/** Response to the query for fetching the tenant billing customer secret */
export type GetTenantBillingCustomerSecretResponse = {
  readonly __typename?: 'GetTenantBillingCustomerSecretResponse';
  /** the secret */
  readonly secret: Scalars['String']['output'];
};

/** For inviting a tenant user */
export type InviteTenantUserRequest = {
  /** the email of the user to invite */
  readonly email: Scalars['String']['input'];
};

/** Response when inviting a tenant user */
export type InviteTenantUserResponse = {
  readonly __typename?: 'InviteTenantUserResponse';
  readonly user: User;
};

/** For attempting to act as another user */
export type MeResponse = {
  readonly __typename?: 'MeResponse';
  /** the user impersonating */
  readonly user: User;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Completes the onboarding of a user */
  readonly completeOnboarding: CompleteOnboardingResponse;
  /** Download a report from a report id */
  readonly downloadReport: ReportResponse;
  /** Generate bulk report for a given list of addresses */
  readonly generateBulkReport: OnlyReportResponse;
  /** Generate single address search report for given coordinates and range */
  readonly generateSingleAddressSearchReport: ReportResponse;
  readonly inviteTenantUser: InviteTenantUserResponse;
  /** Regenerate a bulk report from a report id */
  readonly regenerateBulkReport: OnlyReportResponse;
  /** Regenerate a single address report from a report id */
  readonly regenerateSingleAddressReport: ReportResponse;
  /** Completes the onboarding of a user */
  readonly sendOtpVerification: SendOtpVerificationResponse;
  /** validate addresses contained in the uploaded file */
  readonly validateAddresses: ValidateAddressesResponse;
};

export type MutationCompleteOnboardingArgs = {
  input: CompleteOnboardingRequest;
};

export type MutationDownloadReportArgs = {
  input: DownloadReportRequest;
};

export type MutationGenerateBulkReportArgs = {
  addresses: ReadonlyArray<BulkSearchAddressItemInput>;
  aggregation: ReportAggregation;
};

export type MutationGenerateSingleAddressSearchReportArgs = {
  input: GenerateSingleAddressSearchReportRequest;
};

export type MutationInviteTenantUserArgs = {
  input: InviteTenantUserRequest;
};

export type MutationRegenerateBulkReportArgs = {
  input: RegenerateReportRequest;
};

export type MutationRegenerateSingleAddressReportArgs = {
  input: RegenerateReportRequest;
};

export type MutationSendOtpVerificationArgs = {
  input: SendOtpVerificationRequest;
};

export type MutationValidateAddressesArgs = {
  files: ReadonlyArray<Scalars['Upload']['input']>;
};

/** code representing a network of a particular site */
export enum NetworkCode {
  Abm = 'ABM',
  Ampup = 'AMPUP',
  Blink = 'BLINK',
  BpPulse = 'BP_PULSE',
  Chargelab = 'CHARGELAB',
  Chargenet = 'CHARGENET',
  Chargeup = 'CHARGEUP',
  ChargePoint = 'CHARGE_POINT',
  Chargie = 'CHARGIE',
  CircleK = 'CIRCLE_K',
  ElectrifyAmerica = 'ELECTRIFY_AMERICA',
  Evcs = 'EVCS',
  Evgateway = 'EVGATEWAY',
  Evgo = 'EVGO',
  EvConnect = 'EV_CONNECT',
  EvPower = 'EV_POWER',
  EvRange = 'EV_RANGE',
  Fcn = 'FCN',
  Flo = 'FLO',
  Fplev = 'FPLEV',
  GmUltium = 'GM_ULTIUM',
  GravitiEnergy = 'GRAVITI_ENERGY',
  Jule = 'JULE',
  Livingston = 'LIVINGSTON',
  Loop = 'LOOP',
  MercedesBenz = 'MERCEDES_BENZ',
  NonNetworked = 'NON_NETWORKED',
  Noodoe = 'NOODOE',
  OpConnect = 'OP_CONNECT',
  Powerflex = 'POWERFLEX',
  PowerNode = 'POWER_NODE',
  RedE = 'RED_E',
  Revel = 'REVEL',
  RivianAdventure = 'RIVIAN_ADVENTURE',
  SevenCharge = 'SEVEN_CHARGE',
  ShellRecharge = 'SHELL_RECHARGE',
  Swtch = 'SWTCH',
  Tesla = 'TESLA',
  Universal = 'UNIVERSAL',
  Volta = 'VOLTA',
  Wave = 'WAVE',
  Zefnet = 'ZEFNET',
}

/** Response containing only a report */
export type OnlyReportResponse = {
  readonly __typename?: 'OnlyReportResponse';
  /** the report */
  readonly report: Report;
};

export enum OtpErrors {
  /** When user gave the wrong code for otp verification. */
  IncorrectCode = 'INCORRECT_CODE',
}

/** used to return metadata of results */
export type PageData = {
  readonly __typename?: 'PageData';
  /** total count of results, regardless of the offset or limit set */
  readonly count: Scalars['Float']['output'];
  /** max number of results to get per offset */
  readonly limit?: Maybe<Scalars['Float']['output']>;
  /** where to start listing results from */
  readonly offset?: Maybe<Scalars['Float']['output']>;
};

/** pricing tier for the platform usage */
export enum PlatformPricingTier {
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  Free = 'FREE',
  Second = 'SECOND',
  Sixth = 'SIXTH',
  Third = 'THIRD',
}

export type Query = {
  readonly __typename?: 'Query';
  /** Returns a random -available- slug for creating a new tenant */
  readonly availableTenantSlug: GetAvailableTenantSlugResponse;
  /** Returns information about the authenticated user. */
  readonly me: MeResponse;
  /** Simple operation that returns "pong" when called, to ensure the API is up */
  readonly ping: Scalars['String']['output'];
  /** Returns reports. */
  readonly reports: ReportsResponse;
  /** Get sites for given coordinates and range */
  readonly sites: GetSitesResponse;
  /** Returns a secret referring to the billing customer. This secret will only be valid for 30 minutes. */
  readonly tenantBillingCustomerSecret: GetTenantBillingCustomerSecretResponse;
  /** Indicates whether the given tenant name/slug is available */
  readonly tenantSlugAvailability: CheckTenantAvailabilityResponse;
  /** Returns users from tenant. */
  readonly tenantUsers: TenantUsersResponse;
};

export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ReportsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<ReportsSortDirective>>;
};

export type QuerySitesArgs = {
  input: GetSitesRequest;
};

export type QueryTenantBillingCustomerSecretArgs = {
  input: GetTenantBillingCustomerSecretRequest;
};

export type QueryTenantSlugAvailabilityArgs = {
  input: CheckTenantAvailabilityRequest;
};

export type QueryTenantUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TenantUsersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<ReadonlyArray<TenantUsersSortDirective>>;
};

/** Request for regenerating a report */
export type RegenerateReportRequest = {
  /** unique id of the report */
  readonly reportId: Scalars['String']['input'];
};

/**
 * Representation of a report for one or multiple addresses.
 *     This model contains all the information to download or reproduce the report. The report itself contains
 *     detailed information about the sites a user has requested (availability, reliability, ports, etc.).
 *
 */
export type Report = {
  readonly __typename?: 'Report';
  /** number of addresses in the report */
  readonly addressCount: Scalars['Int']['output'];
  /** full addresses used for this report, only for display purposes */
  readonly addresses: ReadonlyArray<Scalars['String']['output']>;
  /** data aggregation of the report */
  readonly aggregation: ReportAggregation;
  /** whether or not the report can be regenerated */
  readonly canRegenerate: Scalars['Boolean']['output'];
  /** date at which the report was stored */
  readonly createdAt: Scalars['DateTime']['output'];
  /** number of seconds it took to generate the report. This only applies to bulk report (for now) */
  readonly durationInSeconds?: Maybe<Scalars['Float']['output']>;
  /** filename of the report, used for future retrieval. Will only be present if report is completed. */
  readonly filename?: Maybe<Scalars['String']['output']>;
  /** filters originally used to produce this report */
  readonly filters?: Maybe<ReportFilters>;
  /** unique id */
  readonly id: Scalars['ID']['output'];
  /** custom name given by the user */
  readonly name: Scalars['String']['output'];
  /** date at which the report will be available for regeneration */
  readonly nextRegenerationDate: Scalars['DateTime']['output'];
  /** stringified request used to generate the report */
  readonly request: Scalars['String']['output'];
  /** version of the stringified request */
  readonly requestVersion: Scalars['Int']['output'];
  /** status of the report */
  readonly status: ReportStatus;
  /** id of the tenant of the user that generated this report */
  readonly tenantId: Scalars['ID']['output'];
  /** type of the report */
  readonly type: ReportType;
  /** the user who generated the report */
  readonly user: User;
  /** id of the user that generated this report */
  readonly userId: Scalars['ID']['output'];
};

export enum ReportAggregation {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export type ReportConnection = {
  readonly __typename?: 'ReportConnection';
  readonly edges?: Maybe<ReadonlyArray<ReportEdge>>;
  readonly pageInfo?: Maybe<ReportPageInfo>;
};

export type ReportEdge = {
  readonly __typename?: 'ReportEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<Report>;
};

export enum ReportErrors {
  /** When a user does not have enough remaining downloads to generate the report. */
  NotEnoughDownloads = 'NOT_ENOUGH_DOWNLOADS',
  /** When a report can only be generated by a paying user. */
  OnlyForPaidUser = 'ONLY_FOR_PAID_USER',
}

/**
 * Represents the filters that a report can be generated with.
 * Note that this does not includes the addresses as they are a first class citizen and it would not make sense to have them as optional.
 *
 */
export type ReportFilters = {
  readonly __typename?: 'ReportFilters';
  /** the range in miles */
  readonly range?: Maybe<Scalars['Int']['output']>;
};

export type ReportPageInfo = {
  readonly __typename?: 'ReportPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Response of a report generation or regeneration */
export type ReportResponse = {
  readonly __typename?: 'ReportResponse';
  /** the link to the report */
  readonly link: Scalars['String']['output'];
  /** the report */
  readonly report: Report;
};

export enum ReportSortBy {
  CreatedAt = 'CREATED_AT',
}

export enum ReportStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
}

export enum ReportType {
  Bulk = 'BULK',
  Single = 'SINGLE',
}

export type ReportsFilters = {
  /** filter on addresses of report */
  readonly address?: InputMaybe<Scalars['String']['input']>;
  /** filter on user id (user that generated the report) */
  readonly userId?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing reports */
export type ReportsResponse = {
  readonly __typename?: 'ReportsResponse';
  readonly page: ReportConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting reports to be sorted */
export type ReportsSortDirective = {
  readonly by: ReportSortBy;
  readonly direction: SortDirection;
};

/** Request for sending an otp verification. Should only be used in the onboarding process. */
export type SendOtpVerificationRequest = {
  /** the number to send the verification to */
  readonly to: Scalars['String']['input'];
};

/** Response of the send otp verification */
export type SendOtpVerificationResponse = {
  readonly __typename?: 'SendOtpVerificationResponse';
  /** whether the verification has been successfully sent */
  readonly ok: Scalars['Boolean']['output'];
};

/**
 * A site is a representation of a charging station.
 *     For example, it contains information about the company that owns the charging station,
 *     the position of the station in relation to the search that returned the station, the number
 *     of charging ports available at the station and more.
 *
 */
export type Site = {
  readonly __typename?: 'Site';
  /** coordinates of this site */
  readonly coordinates: GeoCoordinates;
  /** distance of this site from the searched address */
  readonly distance: Scalars['Float']['output'];
  /** unique id */
  readonly id: Scalars['ID']['output'];
  /** whether the site will be included in a report or not */
  readonly includedInReport: Scalars['Boolean']['output'];
  /** max speed of this site in kWh */
  readonly maxSpeed: Scalars['Float']['output'];
  /** name of this site */
  readonly name: Scalars['String']['output'];
  /** code of the network of this site */
  readonly networkCode: NetworkCode;
  /** name of the network of this site */
  readonly networkName: Scalars['String']['output'];
  /** number of ports for this site */
  readonly portsCount: Scalars['Int']['output'];
  /** the status of this site */
  readonly status: SiteStatus;
  /** the tracking state of this site */
  readonly trackingState: TrackingState;
};

/** defines the current status of a site within Paren */
export enum SiteStatus {
  Active = 'ACTIVE',
  ComingSoon = 'COMING_SOON',
  Private = 'PRIVATE',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Representation of a tenant of the application/API */
export type Tenant = {
  readonly __typename?: 'Tenant';
  /** tenant address */
  readonly address: Scalars['String']['output'];
  /** api key required to call the site api */
  readonly apiKey: Scalars['String']['output'];
  /** (friendly) display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** tenant features */
  readonly features: ReadonlyArray<TenantFeatures>;
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** unique id of the owner of this tenant */
  readonly ownerId: Scalars['String']['output'];
  /** the permissions of the tenant */
  readonly permissions: TenantPermissions;
  /** current platform pricing tier of this tenant */
  readonly platformPricingTier: PlatformPricingTier;
  /** number of remaining downloads for the current billing period */
  readonly remainingDownloads: Scalars['Int']['output'];
  /** mandatory slug */
  readonly slug: Scalars['String']['output'];
};

export enum TenantFeatures {
  TeslaData = 'TESLA_DATA',
}

/** Permissions that a tenant has on the platform. They are based on the subscription of the tenant. */
export type TenantPermissions = {
  readonly __typename?: 'TenantPermissions';
  /** levels of aggregation this tenant has access to */
  readonly aggregationLevels: ReadonlyArray<ReportAggregation>;
  /** countries the tenant has access to, an empty list means the tenant has access to all countries */
  readonly countries: ReadonlyArray<Scalars['String']['output']>;
  /** additional sources of data this tenant has access to */
  readonly dataSources: ReadonlyArray<DataSource>;
  /** unique id of the tenant permissions */
  readonly id: Scalars['String']['output'];
  /** maximum site lookup search range */
  readonly maxRange: Scalars['Int']['output'];
  /** number of reports downloads allowed per month */
  readonly monthlyDownloads: Scalars['Int']['output'];
  /** whether report data are view-restricted for the tenant or not */
  readonly restrictedData: Scalars['Boolean']['output'];
  /** states the tenant has access to (only applies to US), an empty list means the tenant has access to all states */
  readonly states: ReadonlyArray<Scalars['String']['output']>;
  /** unique id of the tenant associated to those permissions */
  readonly tenantId: Scalars['String']['output'];
  /** last date at which the permissions were updated */
  readonly updatedAt: Scalars['DateTime']['output'];
};

export enum TenantUserSortBy {
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
}

export type TenantUsersFilters = {
  /** filter on status */
  readonly statuses?: InputMaybe<ReadonlyArray<UserStatus>>;
  /** filter on fields of user */
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing users from tenant */
export type TenantUsersResponse = {
  readonly __typename?: 'TenantUsersResponse';
  readonly page: UserConnection;
  readonly pageData?: Maybe<PageData>;
};

/** For requesting tenant users to be sorted */
export type TenantUsersSortDirective = {
  readonly by: TenantUserSortBy;
  readonly direction: SortDirection;
};

/** defines the current tracking state of a site within Paren */
export enum TrackingState {
  NotTracked = 'NOT_TRACKED',
  Tracked = 'TRACKED',
}

/** Representation of a user of the application/API */
export type User = {
  readonly __typename?: 'User';
  /** whether the terms and conditions have been accepted */
  readonly areTermsAndConditionsAccepted: Scalars['Boolean']['output'];
  /** date at which the user was created (or invited) */
  readonly createdAt: Scalars['DateTime']['output'];
  /** the default tenant of the user (who may not have one) */
  readonly defaultTenant?: Maybe<Tenant>;
  /** default tenant id, can be null if there is not tenant at all as the user has not finished onboarding */
  readonly defaultTenantId?: Maybe<Scalars['String']['output']>;
  /** concatenation of first and last name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** email */
  readonly email: Scalars['String']['output'];
  /** id of this user in the notification system */
  readonly externalNotificationId: Scalars['String']['output'];
  /** first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** whether the user has completed onboarding */
  readonly hasCompletedOnboarding: Scalars['Boolean']['output'];
  /** unique id */
  readonly id: Scalars['String']['output'];
  /** whether the email has been verified by the auth system */
  readonly isEmailVerified: Scalars['Boolean']['output'];
  /** whether the phone has been verified via OTP */
  readonly isPhoneVerified: Scalars['Boolean']['output'];
  /** the preferred language */
  readonly language?: Maybe<UserLanguage>;
  /** last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** user phone number */
  readonly phone?: Maybe<Scalars['String']['output']>;
  /** position that this user holds in his tenant */
  readonly position?: Maybe<Scalars['String']['output']>;
  /** user role in the associated tenant */
  readonly role?: Maybe<UserRole>;
  /** user status */
  readonly status: UserStatus;
  /** the version of the API. For now we're putting it on the user resolver because it's the first call we make, but we should really have a 'boot' or 'config' endpoint that returns all user info and system information. */
  readonly version: Scalars['String']['output'];
};

export type UserConnection = {
  readonly __typename?: 'UserConnection';
  readonly edges?: Maybe<ReadonlyArray<UserEdge>>;
  readonly pageInfo?: Maybe<UserPageInfo>;
};

export type UserEdge = {
  readonly __typename?: 'UserEdge';
  readonly cursor?: Maybe<Scalars['String']['output']>;
  readonly node?: Maybe<User>;
};

export enum UserLanguage {
  English = 'ENGLISH',
  French = 'FRENCH',
}

export type UserPageInfo = {
  readonly __typename?: 'UserPageInfo';
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
  System = 'SYSTEM',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
}

/** Response of the addresses validation */
export type ValidateAddressesResponse = {
  readonly __typename?: 'ValidateAddressesResponse';
  /** all addresses */
  readonly addresses: ReadonlyArray<BulkSearchAddressItem>;
  /** number of invalid addresses */
  readonly invalidCount: Scalars['Int']['output'];
  /** total number of addresses */
  readonly totalCount: Scalars['Int']['output'];
};

import { UserContext } from '@application/contexts';
import { TenantPermissions } from '@domain/graphql.types';
import { InfoOutlined } from '@mui/icons-material';
import { alpha, Box, Chip, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { TFunction } from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const getRows = (t: TFunction, tenantPermissions: TenantPermissions | undefined) => [
  {
    key: 'aggregationLevels',
    header: t('tenant.permissions.aggregationLevels'),
    value: (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {tenantPermissions?.aggregationLevels.map((aggregation) => (
          <Chip
            key={aggregation}
            label={t(`enum.reportAggregation.${aggregation}`)}
            sx={{
              backgroundColor: alpha('#076FDB', 0.13),
              color: 'info.dark',
            }}
          />
        ))}
      </Box>
    ),
  },
  {
    key: 'maxRange',
    header: t('tenant.permissions.maxRange'),
    value: (
      <Typography variant="body1" fontWeight="medium">
        {t('suffix.unit.mile', { count: tenantPermissions?.maxRange })}
      </Typography>
    ),
  },
  {
    key: 'monthlyDownloads',
    header: t('tenant.permissions.monthlyDownloads'),
    value: (
      <Typography variant="body1" fontWeight="medium">
        {tenantPermissions?.monthlyDownloads}
      </Typography>
    ),
  },
  {
    key: 'dataSources',
    header: t('tenant.permissions.dataSources'),
    value: (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {tenantPermissions?.dataSources.length ? (
          tenantPermissions?.dataSources.map((dataSource) => (
            <Chip
              key={dataSource}
              label={t(`enum.dataSources.${dataSource}`)}
              sx={{
                backgroundColor: alpha('#076FDB', 0.13),
                color: 'info.dark',
              }}
            />
          ))
        ) : (
          <Typography variant="body1" fontWeight="medium">
            {t('tenant.permissions.none')}
          </Typography>
        )}
      </Box>
    ),
  },
  {
    key: 'countries',
    header: t('tenant.permissions.countries'),
    value: (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {tenantPermissions?.countries.length ? (
          tenantPermissions?.countries.map((country) => (
            <Chip
              key={country}
              label={country}
              sx={{
                backgroundColor: alpha('#076FDB', 0.13),
                color: 'info.dark',
              }}
            />
          ))
        ) : (
          <Typography variant="body1" fontWeight="medium">
            {t('tenant.permissions.allCountries')}
          </Typography>
        )}
      </Box>
    ),
  },
  {
    key: 'states',
    header: (
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography variant="body1" fontWeight="medium">
          {t('tenant.permissions.states')}
        </Typography>

        <Tooltip title={t('tenant.permissions.statesInfo')} arrow placement="top">
          <InfoOutlined fontSize="small" sx={{ color: 'info.dark' }} />
        </Tooltip>
      </Box>
    ),
    value: (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {tenantPermissions?.states.length ? (
          tenantPermissions?.states.map((country) => (
            <Chip
              key={country}
              label={country}
              sx={{
                backgroundColor: alpha('#076FDB', 0.13),
                color: 'info.dark',
              }}
            />
          ))
        ) : (
          <Typography variant="body1" fontWeight="medium">
            {t('tenant.permissions.allStates')}
          </Typography>
        )}
      </Box>
    ),
  },
];

export const TenantPermissionsTable = () => {
  const { t } = useTranslation();

  const { typography, palette } = useTheme();

  const { tenantPermissions } = useContext(UserContext);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {getRows(t, tenantPermissions).map((row) => (
            <TableRow
              key={row.key}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:nth-of-type(even)': {
                  backgroundColor: palette.action.hover,
                },
                '& td, & th': {
                  borderBottom: `1px solid ${palette.divider}`,
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: typography.body1.fontSize,
                  fontWeight: typography.fontWeightMedium,
                }}
                align="left"
              >
                {row.header}
              </TableCell>

              <TableCell sx={{ width: '70%' }}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TenantPermissionsTable;

import PlanGuard from './PlanGuard';
import { paidPlatformPricingTiers } from '@domain/index';

type PayingCustomerGuardProps = React.PropsWithChildren<{
  noRedirect?: boolean;
}>;

const PayingCustomerGuard = ({ children, noRedirect }: PayingCustomerGuardProps) => {
  return (
    <PlanGuard authorizedTiers={paidPlatformPricingTiers} noRedirect={noRedirect}>
      {children}
    </PlanGuard>
  );
};

export default PayingCustomerGuard;

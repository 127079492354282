import { LinkComponent } from '@application/components';
import { UserContext } from '@application/contexts';
import { PlatformPricingTier } from '@domain/graphql.types';
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import TenantPermissionsTable from './TenantPermissionsTable';

const TenantSubscription = () => {
  const { t } = useTranslation();

  const { typography } = useTheme();

  const { tenant } = useContext(UserContext);

  const tierKey = `enum.platformPricingTier.${tenant?.platformPricingTier}`;

  return tenant?.platformPricingTier === PlatformPricingTier.Free ? (
    <Stack gap={2} alignItems="center" marginTop={2}>
      <Typography sx={{ width: 380, textAlign: 'center', fontSize: typography.h4 }}>{t('tenant.subscription.customPlan')}</Typography>

      <Button
        variant="contained"
        LinkComponent={LinkComponent}
        href={import.meta.env.VITE_REACT_APP_PAREN_CONTACT}
        target="_blank"
        sx={{ width: 'fit-content' }}
      >
        {t('tenant.subscription.contactUs')}
      </Button>
    </Stack>
  ) : (
    <Stack gap={2} alignItems="center" marginTop={2}>
      <Typography>
        <Trans t={t} i18nKey="tenant.subscription.currentPlan" values={{ tier: t(tierKey) }} />
      </Typography>

      <Typography>{t('tenant.remainingDownloads', { count: tenant?.remainingDownloads })}</Typography>

      <Button
        variant="contained"
        LinkComponent={LinkComponent}
        href={import.meta.env.VITE_REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
        target="_blank"
        sx={{ width: 'fit-content' }}
      >
        {t('tenant.subscription.manage')}
      </Button>

      <Divider
        sx={{
          marginY: 2,
          width: '100%',
        }}
      />

      <Typography component="h3" variant="h3" alignSelf="flex-start">
        {t('tenant.permissions.heading')}
      </Typography>

      <TenantPermissionsTable />
    </Stack>
  );
};

export default TenantSubscription;

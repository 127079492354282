import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactElement;
  userHasEnoughDownloads: boolean;
};

const GenerateButtonTooltip = ({ children, userHasEnoughDownloads }: Props) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (!userHasEnoughDownloads) {
      return (
        <Trans
          i18nKey={'tooltips.notEnoughDownloads'}
          t={t}
          components={{
            LearnMore: <></>,
          }}
        />
      );
    }

    return null;
  }, [userHasEnoughDownloads, t]);

  return (
    <Tooltip disableInteractive={userHasEnoughDownloads} placement="top" title={title}>
      {children}
    </Tooltip>
  );
};

export default GenerateButtonTooltip;

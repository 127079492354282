import LinkComponent from '@application/components/link-component/LinkComponent';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, TooltipProps, styled, tooltipClasses, useTheme } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router';
import { MenuItem } from './SideBarItems';
import useIsAuthorized from './useIsAuthorized';
import { UserContext } from '@application/contexts';
import { PlatformPricingTier } from '@domain/graphql.types';

type Props = {
  child?: boolean;
  isSideBarOpen: boolean;
  item: MenuItem;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F5F5F5',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: '4px 0px',
    minWidth: '200px',
  },
}));

const SideBarItem = ({ child, isSideBarOpen, item }: Props) => {
  const { t } = useTranslation();

  const { tenant } = useContext(UserContext);

  const { typography } = useTheme();

  const matches = useMatches();

  const checkIsActive = useCallback(
    (entry: MenuItem) => {
      const possibleLocations = [
        entry.to.toString(),
        ...(entry.children?.map(({ to }) => to.toString()) || []),
        ...(entry.activeFor?.map((route) => route.toString()) || []),
      ];

      return matches.some(({ pathname }) => possibleLocations.includes(pathname));
    },
    [matches],
  );

  const hasValidPricingTier = useCallback(
    (tiers?: PlatformPricingTier[]) => (tiers ? tenant?.platformPricingTier && tiers.includes(tenant.platformPricingTier) : true),
    [tenant?.platformPricingTier],
  );

  const checkIsAuthorized = useIsAuthorized();

  const isActive = useMemo(() => checkIsActive(item), [checkIsActive, item]);

  const hasChildren = item.children?.length;
  const color = isActive ? 'primary.main' : '';
  const fontWeight = isActive ? 600 : typography.fontWeightRegular;

  return (
    <StyledTooltip
      title={
        <List>
          {item.children
            ?.filter(({ roles }) => checkIsAuthorized(roles))
            .map((entry) => {
              const color = checkIsActive(entry) ? 'primary.main' : '';
              const fontWeight = checkIsActive(entry) ? 600 : typography.fontWeightRegular;

              return (
                <ListItem key={entry.translationKey} dense sx={{ width: '100%' }}>
                  <ListItemButton
                    disabled={!hasValidPricingTier(entry.pricingTiers)}
                    href={entry.to.toString() || '#'}
                    sx={{ justifyContent: 'space-between', paddingX: '7px', gap: 1, '&.Mui-disabled': { pointerEvents: 'auto' } }}
                    LinkComponent={hasValidPricingTier(item.pricingTiers) ? LinkComponent : 'div'}
                  >
                    <Tooltip
                      key={entry.translationKey}
                      disableHoverListener={hasValidPricingTier(entry.pricingTiers)}
                      title={t('sideBarItem.wrongPlan')}
                      placement="right"
                      arrow
                    >
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box display="inline-flex" alignItems="center" gap={1}>
                          <ListItemIcon sx={{ color, minWidth: '0' }}>{entry.icon}</ListItemIcon>
                          <ListItemText sx={{ color, '> span': { fontWeight } }} primary={t(entry.translationKey)} />
                        </Box>

                        {!hasValidPricingTier(entry.pricingTiers) && (
                          <ListItemIcon sx={{ '&&': { color: 'text.primary' }, minWidth: '0' }}>
                            <LockOutlined fontSize="small" />
                          </ListItemIcon>
                        )}
                      </Box>
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      }
      disableHoverListener={!hasChildren || isSideBarOpen}
      disableTouchListener={!hasChildren || isSideBarOpen}
      placement="right-start"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -20],
              },
            },
          ],
        },
      }}
    >
      <ListItem key={item.translationKey} dense sx={{ width: isSideBarOpen ? '100%' : 'unset' }}>
        <ListItemButton
          disabled={!hasValidPricingTier(item.pricingTiers)}
          href={item.to.toString() || '#'}
          sx={{ justifyContent: 'space-between', paddingX: '7px', paddingY: child ? 0 : 'inherit', gap: 1, '&.Mui-disabled': { pointerEvents: 'auto' } }}
          LinkComponent={hasValidPricingTier(item.pricingTiers) ? LinkComponent : 'div'}
        >
          <Tooltip
            disableHoverListener={hasValidPricingTier(item.pricingTiers)}
            title={hasValidPricingTier(item.pricingTiers) ? null : t('sideBarItem.wrongPlan')}
            placement="right"
            arrow
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box display="inline-flex" alignItems="center" gap={1}>
                <ListItemIcon sx={{ color, minWidth: '0' }}>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color, visibility: isSideBarOpen ? 'visible' : 'hidden', '> span': { fontWeight } }} primary={t(item.translationKey)} />
              </Box>

              {!hasValidPricingTier(item.pricingTiers) && (
                <ListItemIcon sx={{ '&&': { color: 'text.primary' }, minWidth: '0' }}>
                  <LockOutlined fontSize="small" />
                </ListItemIcon>
              )}
            </Box>
          </Tooltip>
        </ListItemButton>
      </ListItem>
    </StyledTooltip>
  );
};

export default SideBarItem;

import UserContext from '@application/contexts/UserContext';
import { Routes } from '@application/routes';
import { PlatformPricingTier } from '@domain/graphql.types';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

type PlanGuardProps = React.PropsWithChildren<{
  authorizedTiers?: PlatformPricingTier[] | null;
  noRedirect?: boolean;
}>;

const PlanGuard = ({ children, authorizedTiers, noRedirect }: PlanGuardProps) => {
  const { tenant } = useContext(UserContext);
  const navigate = useNavigate();

  const isAuthorized = useMemo(
    () => authorizedTiers && tenant?.platformPricingTier && authorizedTiers.includes(tenant.platformPricingTier),
    [authorizedTiers, tenant?.platformPricingTier],
  );

  useEffect(() => {
    if (!isAuthorized && !noRedirect) {
      navigate(Routes.SITE_LOOKUP, { replace: true });
    }
  }, [isAuthorized, noRedirect, navigate]);

  return isAuthorized && (children as any);
};

export default PlanGuard;
